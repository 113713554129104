import { formatCustomDate } from '../../utility/Utils'

/**
 * mapped Product Detail
 * @param {Array}values
 * @param {String}unit
 * @param startDate
 * @returns {{unit, datasets: [{data: (*|*[]), label: string}], labels: (*|*[])}}
 */
export const mappedProductDetail = (values, unit, startDate, t) => {
  return {
    unit,
    labels: values?.map((item) => formatCustomDate(startDate, item[unit], unit, item?.year)) || [],
    datasets: [
      {
        label: t ? t(`SECTION_TWO.CHART_ONE.TOOLTIP_LABEL`, { ns: 'products' }) : '',
        data: values?.map((item) => item.articles_count) || [],
      },
    ],
  }
}
