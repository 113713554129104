import HorizontalBar from '@assets/icons/placeholder_chart_horizontal_bar.svg'
import PieChart from '@assets/icons/pie_chart_placeholder.svg'
import InfluencerPlaceholder from '@assets/icons/placeholder_influencer.svg'
import VerticalBar from '@assets/icons/placeholder_chart_vertical_bar.svg'
import Table from '@assets/icons/table_placeholder.svg'
import Influencer from '@assets/icons/placeholder_influencer.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

const sizes = {
  xs: 100,
  ss: 150,
  small: 200,
  medium: 250,
  default: 300,
  large: 400,
  larger: 500,
}
function PlaceHolder({ variant, label = 'No Data', size = 'ss' }) {
  const { t } = useTranslation(['translation'])
  const images = {
    horizontalBar: HorizontalBar,
    verticalBar: VerticalBar,
    pieChart: PieChart,
    influencer: InfluencerPlaceholder,
    table: Table,
    influencer: Influencer,
  }
  const ImgPlaceHolder = variant ? images[variant] : images['verticalBar']

  return (
    <div className='d-flex justify-content-center align-items-center flex-column h-100'>
      <div style={{ width: sizes[size], height: 'auto' }}>{<img src={ImgPlaceHolder} />}</div>
      {label.length > 0 && (
        <div>
          <h5 style={{ fontFamily: 'inherit' }} className='h5'>
            {t('NO_DATA', { ns: 'translation' })}
          </h5>
        </div>
      )}
    </div>
  )
}

export default PlaceHolder
