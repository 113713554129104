export const env = process.env.REACT_APP_STAGE

const config = {
  prod: {
    apiKey: 'AIzaSyCnSj6AdW0FC65bBQrQNk9l7tSKVPRsrtw',
    authDomain: 'smi-fnac.firebaseapp.com',
    databaseURL: 'https://smi-fnac-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'smi-fnac',
    storageBucket: 'smi-fnac.appspot.com',
    messagingSenderId: '163062500773',
    appId: '1:163062500773:web:3fa894437133a578362c4c',
    measurementId: 'G-Q4N3LN5VVY',
  },
  local: {
    apiKey: 'AIzaSyCBOfkWIKQk3QTMiI_ydwh4IS_AzXWL0m4',
    authDomain: 'smi-fnac-dev.firebaseapp.com',
    databaseURL: 'https://smi-fnac-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'smi-fnac-dev',
    storageBucket: 'smi-fnac-dev.appspot.com',
    messagingSenderId: '347750900723',
    appId: '1:347750900723:web:00ab4fe29e9e2024c72886',
  },
  dev: {
    apiKey: 'AIzaSyCBOfkWIKQk3QTMiI_ydwh4IS_AzXWL0m4',
    authDomain: 'smi-fnac-dev.firebaseapp.com',
    databaseURL: 'https://smi-fnac-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'smi-fnac-dev',
    storageBucket: 'smi-fnac-dev.appspot.com',
    messagingSenderId: '347750900723',
    appId: '1:347750900723:web:00ab4fe29e9e2024c72886',
  },
}

export const socialMediasImages = {
  instagram:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/instagram.svg',
  twitter:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/twitter.svg',
  facebook:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481799/shopmyinfluens/icons/facebook.svg',
  youtube:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/youtube.svg',
  pinterest:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/pinterest.svg',
  site: 'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/web.svg',
  snapchat:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/snapchat.svg',
}

export const firebaseConfig = config[env]
