import _ from 'lodash';
import { formatCustomDate } from '../../utility/Utils';

export const salesChartOld = (values, unit, startDate, influencers) => {
   if (!values) return { unit, labels: [], datasets: [] };

   // FIXME: data should be treated in backend
   const tmpSalesGroupedByHour = _.groupBy(values, unit);
   const tmpSalesGroupedByLabel = _.groupBy(values, 'influencer');
   const labels = getLabels(Object.keys(tmpSalesGroupedByHour), unit, values, startDate);

   const datasets = Object.entries(tmpSalesGroupedByLabel).map(([key1, value]) => ({
      data: Object.entries(tmpSalesGroupedByHour).map(
         ([key2, value2]) => value2.find((item) => item.influencer === key1)?.total_sales ?? null,
      ),
   }));

   return { unit, labels, datasets };
};

export const salesChart = (values, unit, startDate) => {
   if (!Array.isArray(values)) {
      console.error('Invalid values provided to salesChart:', values);
      return null;
   }
   const labels = values.map((value) => formatCustomDate(startDate, value[unit], unit));
   const data = values.map((value) => value['total_sales']);

   return {
      unit,
      labels,
      datasets: {
         data,
      },
   };
};

const getLabels = (list, unit, globalList = [], startDate) => {
   return list.map((item) => {
      return item === 0
         ? startDate
         : formatCustomDate(
              startDate,
              item,
              unit,
              globalList.find((item2) => item2[unit] === item)?.year,
           );
   });
};
