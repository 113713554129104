// ** React Imports
import React, { Fragment, lazy } from 'react';
// ** Layouts
import BlankLayout from '@layouts/BlankLayout';
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper';
import VerticalLayout from '@src/layouts/VerticalLayout';

// ** Route Components
import PrivateRoute from '@components/routes/PrivateRoute';
import PublicRoute from '@components/routes/PublicRoute';

// ** Utils
import { isObjEmpty } from '@utils';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectDisplayCaptcha } from '../../redux/user/user.slice';
import ReCaptcha from '../../views/ReCaptcha';

const getLayout = {
   blank: <BlankLayout />,
   vertical: <VerticalLayout />,
};

// ** Document title
const TemplateTitle = '%s - Fnac Ambassador Insight';

// ** Default Route
const DefaultRoute = '/dashboard';

const Dashboard = lazy(() => import('../../views/Dashboard/Dashboard'));
const PeriodicityDashboard = lazy(() => import('../../views/Periodicity/PeriodicityDashboard'));
const Conversion = lazy(() => import('../../views/Conversion/Conversion'));
const Device = lazy(() => import('../../views/Device/Device'));
const Country = lazy(() => import('../../views/Country/Country'));
const Gifting = lazy(() => import('../../views/Gifting/Gifting'));
const Influencers = lazy(() => import('../../views/Influencers/Influencers'));
const Product = lazy(() => import('../../views/Product/Product'));
const Localization = lazy(() => import('../../views/Localization/Localizations'));
const StatsDashboard = lazy(() => import('../../views/StatsDashboard/StatsDashboard'));
const Login = lazy(() => import('../../views/Login/Login'));
// const Register = lazy(() => import("../../views/Register"))
// const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import('../../views/Error'));

// FIXME: meta:{restricted: true}

// ** Merge Routes

const checkRouteIsHuman = () => {
   const Routes = [
      {
         path: '/',
         index: true,
         element: <Navigate replace to={DefaultRoute} />,
      },
      {
         path: '/dashboard',
         element: (
            <ReCaptcha>
               <Dashboard />
            </ReCaptcha>
         ),
      },
      {
         path: 'periodicity-dashboard',
         element: (
            <ReCaptcha>
               <PeriodicityDashboard />
            </ReCaptcha>
         ),
      },
      {
         path: 'conversion',
         element: (
            <ReCaptcha>
               <Conversion />
            </ReCaptcha>
         ),
      },
      {
         path: 'device',
         element: (
            <ReCaptcha>
               <Device />
            </ReCaptcha>
         ),
      },
      {
         path: 'influencers',
         element: (
            <ReCaptcha>
               <Influencers />
            </ReCaptcha>
         ),
      },
      {
         path: 'products',
         element: (
            <ReCaptcha>
               <Product />
            </ReCaptcha>
         ),
      },
      {
         path: 'localizations',
         element: (
            <ReCaptcha>
               <Localization />
            </ReCaptcha>
         ),
      },
      {
         path: 'category',
         element: (
            <ReCaptcha>
               <StatsDashboard />
            </ReCaptcha>
         ),
      },
      {
         path: '/login',
         element: <Login />,
         meta: {
            layout: 'blank',
         },
      },

      {
         path: '*',
         element: <Error />,
         meta: {
            layout: 'blank',
         },
      },
   ];

   return Routes;
};

const getRouteMeta = (route) => {
   if (isObjEmpty(route.element.props)) {
      if (route.meta) {
         return { routeMeta: route.meta };
      } else {
         return {};
      }
   }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
   const LayoutRoutes = [];
   const DisplayCaptcha = useSelector(selectDisplayCaptcha);

   const Routes = checkRouteIsHuman();

   if (Routes) {
      checkRouteIsHuman().filter((route) => {
         let isBlank = false;
         // ** Checks if Route layout or Default layout matches current layout
         if (
            (route.meta && route.meta.layout && route.meta.layout === layout) ||
            ((route.meta === undefined || route.meta.layout === undefined) &&
               defaultLayout === layout)
         ) {
            // ** Check for public or private route
            if (route.meta) {
               route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false);
            }
            if (route.element) {
               const RouteTag = isBlank ? PublicRoute : PrivateRoute;

               const Wrapper =
                  !isObjEmpty(route.element.props) && isBlank === false && DisplayCaptcha === false
                     ? LayoutWrapper
                     : Fragment;

               route.element = (
                  <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                     <RouteTag route={route}>{route.element}</RouteTag>
                  </Wrapper>
               );
            }

            // Push route to LayoutRoutes
            LayoutRoutes.push(route);
         }
         return LayoutRoutes;
      });
   }
   return LayoutRoutes;
};

const getRoutes = (layout) => {
   const defaultLayout = layout || 'blank';
   const layouts = ['vertical', 'horizontal', 'blank'];

   const AllRoutes = [];

   layouts.forEach((layoutItem) => {
      const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);
      AllRoutes.push({
         path: '/',
         element: getLayout[layoutItem] || getLayout[defaultLayout],
         children: LayoutRoutes,
      });
   });
   return AllRoutes;
};

export { DefaultRoute, TemplateTitle, getRoutes };
