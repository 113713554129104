import { createSlice } from '@reduxjs/toolkit';
import { getInfluencersData, getProductPerformances, getProductStats } from './product.thunk.js';

const initialState = {
   productStatsData: {
      global: {
         productsSold: 0,
         sharedProductsClicksNotSolded: 0,
         average_products_sold: 0,
         sharedProductsClicks: 0,
      },
      charts: {
         productDetail: {
            unit: '',
            labels: [],
            datasets: [],
         },
         saleWithColorDetail: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         categoriesPercent: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         categoriesTotalSales: {
            labels: [],
            datasets: {
               data: [],
            },
         },
      },
      tablesData: {
         selectedProductTableData: [],
         saledProductTableData: [],
      },
      influencersData:{data:[]}
   },
   getProductStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getProductPerformancesStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getInfluencersDataStatus: 'idle',
   errors: {},
};

export const productSlice = createSlice({
   name: 'product',
   initialState,
   extraReducers(builder) {
      builder
         .addCase(getProductStats.pending, (state, action) => {
            state.getProductStatsStatus = 'loading';
            state.productStatsData = initialState.productStatsData;
         })
         .addCase(getProductStats.fulfilled, (state, action) => {
            delete state.errors.stats; //find error in Errors and delete it
            state.productStatsData = { ...state.productStatsData, ...action.payload };
            state.getProductStatsStatus = 'succeeded';
         })
         .addCase(getProductStats.rejected, (state, action) => {
            state.errors = { ...state.errors, stats: action.payload };
            state.getProductStatsStatus = 'failed';
         })

         .addCase(getProductPerformances.pending, (state, action) => {
            state.getProductPerformancesStatus = 'loading';
         })
         .addCase(getProductPerformances.fulfilled, (state, action) => {
            delete state.errors.performances; //find error in Errors and delete it
            state.productStatsData = { ...state.productStatsData, ...action.payload };
            state.getProductPerformancesStatus = 'succeeded';
         })
         .addCase(getProductPerformances.rejected, (state, action) => {
            state.errors = { ...state.errors, performances: action.payload };
            state.getProductPerformancesStatus = 'failed';
         })

         .addCase(getInfluencersData.pending, (state, action) => {
            state.getInfluencersDataStatus = 'loading';
         })
         .addCase(getInfluencersData.fulfilled, (state, action) => {
            delete state.errors.performances; //find error in Errors and delete it
            state.influencersData = action.payload;
            state.getInfluencersDataStatus = 'succeeded';
         })
         .addCase(getInfluencersData.rejected, (state, action) => {
            state.errors = { ...state.errors, performances: action.payload };
            state.getInfluencersDataStatus = 'failed';
         })
   },
});

export const selectProductStatsData = (state) => state.product.productStatsData;

export const selectProductStatsStatus = (state) => state.product.getProductStatsStatus;

export const selectProductPerformancesStatus = (state) =>
   state.product.getProductPerformancesStatus;

export const selectInfluencersData = (state) =>
   state.product.influencersData;
export const selectInfluencersDatastatus = (state) =>
   state.product.getInfluencersDataStatus;

export const selectProductErrorsStats = (state) => state.product.errors?.stats;

export const selectProductErrorsPerformances = (state) => state.product.errors?.performances;

export default productSlice.reducer;
