import axios from 'axios';
import { getUserToken } from '../utility/Utils.js';

// TODO: get Token from sessionStorage
const env = process.env.REACT_APP_STAGE;
let baseURL;

switch (env) {
   case 'prod':
      baseURL = 'https://europe-west1-smi-fnac.cloudfunctions.net/insights';
      break;
   case 'dev':
      baseURL = 'https://europe-west1-smi-fnac-dev.cloudfunctions.net/insights';
      break;
   case 'local':
   default:
      baseURL = 'http://localhost:5001/';
      // baseURL = 'http://localhost:5001/smi-fnac-dev/europe-west1/insights';
      break;
}
const client = axios.create({
   baseURL: baseURL,
});

client.interceptors.request.use(async (request) => {
   const token = await getUserToken();

   if (token) {
      request.headers = {
         Authorization: `Bearer ${token}`,
      };
   } else {
      // prompt user to login?
   }

   return request;
});

export const getData = async (endPoint, { params, ...rest }) => {
   try {
      const response = await client.get(endPoint, { params, ...rest });
      return response.data;
   } catch (err) {
      throw err;
      // return err;
   }
};

export const postData = async (endPoint, { data, ...rest }) => {
   try {
      const response = await client.post(endPoint, { data, ...rest });
      return response.data;
   } catch (err) {
      return err;
   }
};
