import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUnixTime } from 'date-fns'
import { getData } from '../../Api/api'
import { EP_GET_INFLUENCERS, EP_PRODUCTS_PERFORMANCES, EP_PRODUCTS_STATS } from '../../Api/endPoints'
import { chartArrayMapper, customRound } from '../../utility/Utils'
import { mappedProductDetail } from './product.mapper'

export const getProductStats = createAsyncThunk(
  'product/getProductStats',
  async ({ startDate, endDate,influencer = null, brand = 0, language = null, t }, thunkAPI) => {
    try {
      const params = {
        start: getUnixTime(startDate),
        end: getUnixTime(endDate),
        brand,
        influencer,
        // count: 20,
        timezone: new Date().getTimezoneOffset(),
      }

      const {
        shared_clicked_products: sharedClickedProducts,
        products_sold: productsSold,
        products_sold_per_supplier: productsSoldPerSupplier,
        products_sold_per_category: productsSoldCategory,
        products_sold_per_period: productsSoldPerPeriod,
      } = await getData(EP_PRODUCTS_STATS, { params })

      const { productStatsData } = thunkAPI.getState().product
      const paginatedProductsSoldPerCategory =
        productsSoldCategory
          ?.sort((a, b) => b.percent - a.percent)
          .slice(0, 6)
          .filter((c) => c?.key) ?? [] // get last 6 element order by descending percent

      const paginatedProductsSoldPerCategoryFullDetails =
        productsSoldCategory
          ?.sort((a, b) => b.percent - a.percent)
          //.slice(0, 6)
          .filter((c) => c?.key) ?? []
      let percentProductsSoldPerSupplier =
        productsSoldPerSupplier?.sort((a, b) => b.percent - a.percent).slice(0, 6) || []
      let turnoverProductsSoldPerSupplier =
        productsSoldPerSupplier?.sort((a, b) => b.turnover - a.turnover).slice(0, 6) || []

      return {
        ...productStatsData,
        charts: {
          ...productStatsData.charts,
          saleWithCategoryDetail: chartArrayMapper(
            paginatedProductsSoldPerCategory,
            `text-${language}`,
            'percent',
            '',
            '',
            'color',
            'turnover',
          ),
          saleWithCategoryDetailAll: chartArrayMapper(
            paginatedProductsSoldPerCategoryFullDetails,
            `text-${language}`,
            'percent',
            '',
            '',
            'color',
            'turnover',
          ),
          categoriesPercent: chartArrayMapper(
            percentProductsSoldPerSupplier,
            `supplier`,
            'percent',
            '',
            '',
            null,
            null,
            t,
          ),
          categoriesTotalSales: chartArrayMapper(
            turnoverProductsSoldPerSupplier,
            `supplier`,
            'turnover',
            '',
            '',
            null,
            null,
            t,
          ),
          productDetail: mappedProductDetail(
            productsSoldPerPeriod,
            productsSoldPerPeriod?.[0]?.type ?? '',
            startDate,
            t,
          ),
        },
        tablesData: {
          selectedProductTableData: sharedClickedProducts,
          saledProductTableData: productsSold,
          productsSoldPerSupplier: productsSoldPerSupplier,
        },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message)
    }
  },
)

export const getProductPerformances = createAsyncThunk(
  'product/getProductPerformances',
  async ({ startDate, endDate,influencer = null, brand = 0 }, thunkAPI) => {
    try {
      const params = {
        start: getUnixTime(startDate),
        end: getUnixTime(endDate),
        brand,
        influencer,
        // count: 20,
        timezone: new Date().getTimezoneOffset(),
      }

      const {
        shared_products_clicks: sharedProductsClicks,
        products_sold: productsSold,
        average_products_sold: average_products_sold,
      } = await getData(EP_PRODUCTS_PERFORMANCES, { params })

      return {
        global: {
          sharedProductsClicks: sharedProductsClicks ? sharedProductsClicks : 0,
          productsSold: productsSold ? productsSold : 0,
          average_products_sold: average_products_sold ? customRound(average_products_sold) : 0,
          sharedProductsClicksNotSolded:
            sharedProductsClicks && productsSold
              ? Math.abs(sharedProductsClicks - productsSold)
              : 0,
        },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message)
    }
  },
)


export const getInfluencersData = createAsyncThunk(
  'product/getInfluencersData',
  async (_, thunkAPI) => {
    try {
      const result = await getData(EP_GET_INFLUENCERS,{})
      return {data:result}
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message)
    }
  },
)