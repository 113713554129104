// ** React Imports

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { Power } from 'react-feather'

// ** Reactstrap Imports
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

// ** Default Avatar Image
import themeConfig from '@configs/themeConfig'
import { useAuth } from '../../../auth/firebase/useAuth.js'

const UserDropdown = () => {
  const { logoutUser, user } = useAuth()

  const signOut = () => {
    logoutUser()
  }
  // FIXME: fix name and role user

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      {user && (
        <DropdownToggle
          href='/'
          tag='a'
          className='nav-link dropdown-user-link'
          onClick={(e) => e.preventDefault()}
        >
          <div className='user-nav d-sm-flex d-none'>
            <span className='user-name fw-bold'>
              {user.firstName ? user.lastName + ' ' + user?.firstName : user?.email?.split('@')[0]}
            </span>
            {/* <span className='user-status'>{user?.brand?.role ? user?.brand?.role : 'Admin'}</span> */}
          </div>
          <Avatar
            img={user?.brand?.pic ?? themeConfig.app.defaultAvatar}
            imgHeight='40'
            imgWidth='40'
            status='online'
          />
        </DropdownToggle>
      )}
      <DropdownMenu end>
        <DropdownItem onClick={signOut}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
